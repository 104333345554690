import {post, get} from "./request";

// 硬件商城
export const findHardwareStoreData = (data) =>
  post("/api/goods/findHardwareStoreData", data);
// 配方商城
export const findPrescriptionData = (data) =>
  post("/api/goods/findPrescriptionData", data);
// 配方导航
export const findPrescriptionType = (data) =>
  post("/api/goods/findPrescriptionType", data);
// 订单是否支付成功
// export const loopQueryOrderState = (data) => get("/api/pay/loopQueryOrderState", data);
// 设备详情
export const getDeviceInfo = (data) => get("/api/goods/getDeviceInfo", data);
// 配方详情
export const getPrescriptionInfo = (data) =>
  get("/api/goods/getPrescriptionInfo", data);
// 创建配方订单接口
export const CreatePrescription = (data) =>
  post("/api/order/CreatePrescription", data);

// 结算页面
export const preCreate = (data) => post("/api/order/preCreate", data);
// 地址管理
// 收货地址列表
export const queryAddrList = (data) => get("/api/user/queryAddrList", data);
// 编辑收货地址
export const updateAddr = (data) => post("/api/user/updateAddr", data);
// 新增收货地址
export const addAddr = (data) => post("/api/user/addAddr", data);
// 删除收货地址
export const delAddr = (data) => get("/api/user/delAddr", data);
// 设置默认地址
export const setDefault = (data) => get("/api/user/setDefault", data);
// 订单管理
// 全部订单
export const queryAllOrder = (data) => post("/api/order/queryAllOrder", data);
// 创建订单
export const create = (data) => post("/api/order/create", data);
// 取消订单
export const cancelOrder = (data) => get("/api/order/cancelOrder", data);
// 订单支付
export const orderPayment = (data) => post("/api/pay/orderPayment", data);
// 订单收货
export const orderConfirm = (data) => get("/api/order/confirm", data);

// 意见反馈的选项列表
export const getSuggestionTypeList = (data) =>
  get("/api/base/getSuggestionTypeList", data);
//  提交意见反馈
export const addSuggestions = (data) => post("/api/base/addSuggestion", data);
// 注册上传头像
export const uploadRegisterPicHead = (data) =>
  post("/api/user/uploadRegisterPicHead", data);
// 注册发送验证码
export const registerSendCode = (data) => get("/api/sendMsg/register", data);
// 注册
export const register = (data) => post("/api/user/register", data);

// 提醒发货
export const remindTheDelivery = (data) =>
  get("/api/order/remindTheDelivery", data);
// 查看物流
export const getLogistics = (data) => get("/api/order/getLogistics", data);

// 关于我们
export const getAboutusInfo = (data) => get("/api/base/getAboutusInfo", data);

// 登录
export const loginByPwd = (data) => post("/api/user/loginByPwd", data);

export const findOrder = (data) => get("/api/pay/findOrder", data);

export const loginByWx = (data) => get("/api/user/loginByWx", data);

export const bindAccount = (data) => get("/api/user/bindAccount", data);
